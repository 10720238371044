import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import GR from "./locales/gr.json";
import EN from "./locales/en.json";

const resources = {
  en: {
    home: EN,
  },
  gr: {
    home: GR,
  },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  debug: false,
  fallbackLng: "en",
  saveMissing: true,
});

export default i18next;
