interface ItemProps {
  icon: React.ReactNode;
  title: string;
}

export default function InfoItem({ icon, title }: ItemProps) {
  return (
    <div className="flex flex-col text-center justify-center mt-10">
      <div className="mb-4 text-teal-400 m-auto text-5xl">{icon}</div>
      <p className="text-white font-semibold text-base">{title}</p>
    </div>
  );
}
