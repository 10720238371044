export const PROFIL_IMG = "/assets/images/website_photo_profile.jpg"

export const GR_ICON = "/assets/icons/gr-icon.png"
export const EN_ICON = "/assets/icons/en-icon.png"

export const CV_IMG = "/assets/Kostadinos_Ignatiadis_CV.pdf"

export const DESIGN_ICON = "/assets/icons/design.png"
export const CODE_ICON = "/assets/icons/code.png"
export const CONSULTING_ICON = "/assets/icons/consulting.png"

export const PORTFOLIO_IMG_01 = "/assets/images/portfolio/portfolio-1.png"
export const PORTFOLIO_IMG_02 = "/assets/images/portfolio/portfolio-2.png"
