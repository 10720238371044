import {
  CV_IMG,
  PROFIL_IMG,
  DESIGN_ICON,
  CODE_ICON,
  CONSULTING_ICON,
  PORTFOLIO_IMG_01,
  PORTFOLIO_IMG_02,
} from "./constants/common";

import { AiFillLinkedin, AiTwotonePhone, AiFillYahoo } from "react-icons/ai";

import InfoItem from "./components/InfoItem";
import Selector from "./components/Selector";

import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation(["home"]);

  return (
    <div className="dark">
      <main className="bg-white px-10 dark:bg-gray-900 md:px-20 lg:px-40">
        <section className="min-h-screen">
          <nav className="py-10 mb-12 flex justify-between dark:text-white">
            <Selector />
            <ul className="flex items-center">
              <li>
                <a
                  className="bg-gradient-to-r from-cyan-500 text- to-teal-500 text-white px-4 py-2 border-none rounded-md ml-8 font-extrabold"
                  href={CV_IMG}
                >
                  {t("resume-label")}
                </a>
              </li>
            </ul>
          </nav>
          <div className="text-center p-10 py-10">
            <h2 className="text-3xl py-2 font-extrabold text-teal-400 md:text-5xl">
              {t("home-page")}
            </h2>
            <p className="text-md py-5 leading-8 text-gray-200 max-w-1xl mx-auto md:text-xl">
              {t("home-page-summary")}
            </p>
            <div className="text-3xl flex justify-center sm:gap-16 gap-2 sm:flex-row flex-col" >
              <InfoItem
                icon={<AiFillLinkedin />}
                title={"www.linkedin.com/in/kostasigna"}
              />
              <InfoItem
                icon={<AiTwotonePhone />}
                title={"694 792 5950"}
              />
              <InfoItem
                icon={<AiFillYahoo />}
                title={"kostasigna@yahoo.gr"}
              />
            </div>
            <div className="m-auto flex bg-gradient-to-b from-teal-500 rounded-full w-60 h-60 relative overflow-hidden mt-20 md:h-96 md:w-96">
              <img src={PROFIL_IMG} width={"100%"} height={"100%"} className="object-cover" alt={""} />
            </div>
          </div>
        </section>
        <section className="text-center">
          <h3 className="text-3xl py-1 dark:text-white font-extrabold">{t("services-page")}</h3>

          <div className="lg:flex gap-10">
            <div className="text-center shadow-lg p-10 rounded-xl my-10  dark:bg-white flex-1">
              <img src={DESIGN_ICON} width={100} height={100} alt={""} className="m-auto" />
              <h3 className="text-lg font-bold pt-8 pb-2  ">
                {t("service-01")}
              </h3>
              <h4 className="py-2 text-teal-600 font-semibold">
                {t("service-01-title")}
              </h4>
              <p className="text-gray-800 py-1">Bootstrap</p>
              <p className="text-gray-800 py-1">Bulma</p>
              <p className="text-gray-800 py-1">MUI</p>
              <p className="text-gray-800 py-1">Tailwind</p>
            </div>
            <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
              <img src={CODE_ICON} width={100} height={100} alt={""} className="m-auto" />
              <h3 className="text-lg font-bold pt-8 pb-2 ">
                {t("service-02")}
              </h3>
              <h4 className="py-2 text-teal-600 font-semibold">
                {t("service-02-title")}
              </h4>
              <p className="text-gray-800 py-1">CSS/HTML</p>
              <p className="text-gray-800 py-1">JavaScript</p>
              <p className="text-gray-800 py-1">React</p>
              <p className="text-gray-800 py-1">Typescript</p>
            </div>
            <div className="text-center shadow-lg p-10 rounded-xl my-10 dark:bg-white flex-1">
              <img src={CONSULTING_ICON} width={100} height={100} alt={""} className="m-auto" />
              <h3 className="text-lg font-bold pt-8 pb-2 ">{t("service-03")}</h3>
              <h4 className="py-2 text-teal-600 font-semibold">
                {t("service-03-title")}
              </h4>
              <p className="text-gray-800 py-1">Linux-Ubuntu</p>
              <p className="text-gray-800 py-1">Windows 7</p>
              <p className="text-gray-800 py-1">Windows 10</p>
              <p className="text-gray-800 py-1">Windows 11</p>
            </div>
          </div>
        </section>
        <section className="py-10 text-center">
          <h3 className="text-3xl py-1 dark:text-white font-extrabold">{t("portfolio-page")}</h3>

          <div className="flex flex-col gap-10 py-10 lg:flex-row lg:flex-wrap">
            <div className="basis-1/3 flex-1 border-4 border-gray-300 rounded-xl ">
              <a href="https://qr-generator-akd1bgznl-kostadinos-igns-projects.vercel.app/">
                <img
                  className="rounded-lg object-cover"
                  width={"100%"} height={"100%"}
                  src={PORTFOLIO_IMG_01} alt={"QR_Generator_Project"}
                />
              </a>
            </div>
            <div className="basis-1/3 flex-1 border-4 border-gray-300 rounded-xl">
              <a href="https://art-station-01.firebaseapp.com/">
                <img
                  className="rounded-lg object-cover"
                  width={"100%"} height={"100%"}
                  src={PORTFOLIO_IMG_02} alt={"Art_Station_Project"}
                />
              </a>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}
