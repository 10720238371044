import { useState } from 'react'

import { useTranslation } from "react-i18next";
import { EN_ICON, GR_ICON } from '../constants/common';

export default function Selector() {
  const [select, setSelect] = useState<boolean>(true);

  const { i18n } = useTranslation(["home"]);

  const handleGRClick = () => {
    i18n.changeLanguage("gr");
    setSelect(false);
  }

  const handleENClick = () => {
    i18n.changeLanguage("en");
    setSelect(true);
  }

  return (
    <div>
      {select ? (
        <button onClick={handleGRClick}>
          <img src={EN_ICON} alt='English Icon' />
        </button>
      ) : (
        <button onClick={handleENClick}>
          <img src={GR_ICON} alt='Greek Icon' />
        </button>
      )}
    </div>
  )
}
